import Cookies from 'universal-cookie';


const cookies = new Cookies();

let languageSettings = [{"locale": "ar", 'direction': 'rtl', 'lang': 'eg', 'country': 'العربية'}, {
    "locale": "en",
    'direction': 'ltr',
    'lang': 'us',
    'country': 'English'
}];

// let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
// cookies.set('locale', locale)
let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
cookies.set('lang', languageSettings.find(x => x.locale === locale).lang)
cookies.set('country', languageSettings.find(x => x.locale === locale).country)
// cookies.set('direction', direction)

// You can customize the theme with the help of this file
//Template config options
const themeConfig = {
    layout: "vertical", // options[String]: "vertical"(default), "horizontal"
    theme: "light", // options[String]: 'light'(default), 'dark', ''
    sidebarCollapsed: false, // options[Boolean]: true, false(default)
    navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
    navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
    footerType: "static", // options[String]: static(default) / sticky / hidden
    disableCustomizer: true, // options[Boolean]: true, false(default)
    hideScrollToTop: false, // options[Boolean]: true, false(default)
    menuTheme: "warning", // options[String]: primary / success / danger / info / warning / dark
    defaultLanguage: locale, // options[String]: en / fr
    direction: languageSettings.find(x => x.locale === locale).direction // options[String] : ltr / rtl
}

export default themeConfig
