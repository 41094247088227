import React from "react"
import {IntlProvider} from "react-intl"

import messages_en from "../../assets/data/locales/en.json"
import messages_de from "../../assets/data/locales/de.json"
import messages_fr from "../../assets/data/locales/fr.json"
import messages_pt from "../../assets/data/locales/pt.json"
import messages_ar from "../../assets/data/locales/ar.json"
import themeConfig from "../../configs/themeConfig"

const menu_messages = {
    en: messages_en,
    de: messages_de,
    fr: messages_fr,
    pt: messages_pt,
    ar: messages_ar
}

const Context = React.createContext()

class IntlProviderWrapper extends React.Component {
    state = {
        locale: themeConfig.defaultLanguage,
        messages: menu_messages[themeConfig.defaultLanguage],
        direction: themeConfig.direction,
        activeLayout: themeConfig.layout,
    }

    componentDidUpdate() {
        this.handleDirUpdate()
    }


    handleDirUpdate = () => {
        let dir = this.state.direction
        if (dir === "rtl")
            document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
        else
            document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
    }

    render() {
        const {children} = this.props
        const {locale, messages} = this.state

        if (locale === "en") {
            return (
                <Context.Provider
                    value={{
                        state: this.state,
                        switchLanguage: (dir) => {
                            this.setState({
                                direction: dir,
                                // locale:{locale},
                                // messages:{messages}
                                // locale:"fr",

                                // messages: menu_messages["fr"],
                            })
                        },
                        switchDir: (language) => {
                            this.setState({
                                locale: language,
                                messages: menu_messages[language],
                            })
                        }
                        // switchDir: dir => {
                        //   this.setState({ direction: dir })
                        // }
                    }}
                >
                    <IntlProvider
                        key={messages}
                        messages={messages}
                        defaultLocale="en"
                    >
                        {children}
                    </IntlProvider>
                </Context.Provider>
            )
        } else return (
            <Context.Provider
                value={{
                    state: this.state,
                    switchLanguage: (dir) => {
                        this.setState({
                            direction: dir,
                            // locale:{locale},
                            // messages:{messages}
                            // locale:"en",
                            // messages: menu_messages["en"],
                        })
                    },
                    switchDir: (language) => {
                        this.setState({
                            locale: language,
                            messages: menu_messages[language],
                        })
                    }
                }}
            >
                <IntlProvider
                    key={messages}
                    messages={messages}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        )

    }
}

export {IntlProviderWrapper, Context as IntlContext}
