import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
    {
        type: "groupHeader",
        groupTitle: "Variiance Courses"
    },
    {
        id: "page3",
        title: "Active Courses",
        type: "item",
        icon: <Icon.BookOpen size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/"
    },

    {
        id: "page5",
        title: "Upcoming Courses",
        type: "item",
        icon: <Icon.Clock size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/upcomingcourses"
    },
    {
        id: "page4",
        title: "Course Log",
        type: "item",
        icon: <Icon.CheckCircle size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/courselog"
    },
    {
        type: "groupHeader",
        groupTitle: "Variiance Teaching System"
    },


    {
        id: "vtsHome",
        title: "vtsHome",
        type: "item",
        icon: <Icon.Home size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/vtshome"

    },
    // {
    //     id: "vts_Sessions",
    //     title: "Sessions tabs",
    //     type: "collapse",
    //     icon: <Icon.Clock size={20}/>,
    //     permissions: ["admin", "editor"],
    //     navLink: "/vtsSessions",
    //     children: [
    {
        id: "Paid_Sessions",
        title: "PaidSessions",
        type: "item",
        icon: <Icon.CreditCard size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/vtsPaidSessions"

    },
    {
        id: "Accepted_Sessions",
        title: "Accepted Sessions",
        type: "item",
        icon: <Icon.CheckCircle size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/vtsAcceptedSessions"

    },
    {
        id: "Pending_Sessions",
        title: "Pending Sessions",
        type: "item",
        icon: <Icon.StopCircle size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/vtsPendingSessions"

    }, {
        id: "Rejected_Sessions",
        title: "Rejected Sessions",
        type: "item",
        icon: <Icon.XCircle size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/vtsRejectedSessions"

    },

    //     ]
    // }
    // {
    //     type: "groupHeader",
    //     groupTitle: "Variiance Life Communication"
    // },
    // {
    //     id: "home",
    //     title: "Home",
    //     type: "item",
    //     icon: <Icon.Home size={20}/>,
    //     permissions: ["admin", "editor"],
    //     navLink: "/vlcHome"
    // },
    // {
    //     id: "Meeting",
    //     title: "Meeting",
    //     type: "item",
    //     icon: <Icon.Calendar size={20}/>,
    //     permissions: ["admin", "editor"],
    //     navLink: "/vlcMeetings"
    // },
    // {
    //     id: "Room",
    //     title: "Room",
    //     type: "item",
    //     icon: <Icon.Users size={20}/>,
    //     permissions: ["admin", "editor"],
    //     navLink: "/vlcRoom"
    // },
    // {
    //     id: "Contacts",
    //     title: "Contacts",
    //     type: "item",
    //     icon: <Icon.User size={20}/>,
    //     permissions: ["admin", "editor"],
    //     navLink: "/VlcContacts"
    // },
    // {
    //     id: "Recording",
    //     title: "Recording",
    //     type: "item",
    //     icon: <Icon.Video size={20}/>,
    //     permissions: ["admin", "editor"],
    //     navLink: "/VlcRecording"
    // },
    // {
    //     id: "Package",
    //     title: "Package",
    //     type: "item",
    //     icon: <Icon.Settings size={20}/>,
    //     permissions: ["admin", "editor"],
    //     navLink: "/Vlcpackage"
    // }

]

export default navigationConfig
